import MicroModal from 'micromodal'
import {initModals} from '../components/modal';

export function productPageScripts() {
	// Custom Datepicker
	datePicker($('.delivery_date'));
	$('.product-info').find('button[name=add-to-cart]').hide();
	$('#addtocart').find('button[name=add-to-cart]').show();

	// Jump straight into the sizes and click on Add to cart just once
	$('#addtocart').show(); // For simple products (not composite)

	// preloadPackageData();

	restoreProductDefaults();

	// Custom Select Boxes
	$('select.custom-select:not(.slide2 select.custom-select)').each(function () {
		styleCustomSelect($(this))
	})

	$(document).on('click', '.qty-update', function (event) {
		event.preventDefault()
		quantities($(this))
	})

	$(document).on('click', '.js-handleBackupSuit', async function (event) {
		// event.stopImmediatePropagation();
		event.preventDefault();
		blockUI('#minicart');
		var backupSuits = JSON.parse($(this).attr('data-backup'));
		for (let backup in backupSuits) {
			if (backupSuits[backup] == 'no') {
				$('input[name="cart[' + backup + '][backup_suit]"').prop(
					'checked',
					true
				)
				if ($(this).hasClass('cart')) {
					await handleBackupSuit(
						$('input[name="cart[' + backup + '][backup_suit]"'),
						true
					)
				} else {
					await handleBackupSuit(
						$('input[name="cart[' + backup + '][backup_suit]"'),
						false
					)
				}
			}
		}
		if ($(this).hasClass('cart')) {
			window.location.href = '/cart'
		} else {
			await update_minicart(); // Refresh the cart to reflect the updated price
		}
		unblockUI('#minicart');
		// return false;
	})

	// Composite product. Expand info 
	$(document).on('click', '.slide1_details', function () {
		$(this).toggleClass('expand');
		$(this).prev().find('.slide1_package').toggleClass('expand');
		if (!$(this).hasClass('expand')) {
			$('.product_img-zoom, .product_img-zoom img').removeClass('slideUp');
			$(this).find('span').html('Details');
		}else{
			$(this).find('span').html('Less');
		}
	});
	$(document).on('click', '.back_to_packages, .addtocart_close', function () {
		$('.product-form_composite').show();
		$('.product-form_details').hide();
		$('#addtocart').remove();
		$([document.documentElement, document.body]).animate({
			scrollTop: $(".product-form_composite-badge").offset().top
		}, 500);
	});
	$(document).on('click', '.product_img-zoom', function () {
		$('.product_img-zoom, .product_img-zoom img').removeClass('slideUp');
		$('body').css('overflow', 'auto');
	});
	$(document).on('click', '.slide1_package-product .product_img-preview', function () {
		// Zoom on expanded card
		if ($(this).height() < 40) {
			return;
		}
		$('.product_img-zoom, .product_img-zoom img').removeClass('slideUp');
		// $(this).next('.product_img-zoom').addClass('zoomed');
		$(this).next('.product_img-zoom').find('img').addClass('slideUp').length || $(this).next('.product_img-zoom').addClass('slideUp');
		$('body').css('overflow', 'hidden');
	});
	$(document).on('click', '.slide1_action-nextstep', function () {
		let slide2 = $(this).parents('.product-form_composite').children('.slide2');
		if (!slide2.html()) return;
		$('.product-form_details').append(slide2.html());
		$('.product-form_details > .product-info').attr('id', 'addtocart');
		setTimeout(() => {
			$('.product-form_details > .package-name').text(slide2.data('package_name'));
			$('.product-form_details > .package-desc').text(slide2.data('package_desc'));
			$('#addtocart').find('button[name=add-to-cart]').show();
			datePicker($('#addtocart .delivery_date'));
			restoreProductDefaults();
			initModals();
			// Custom Select Boxes
			$('.product-form_details select.custom-select').each(function () {
			 	styleCustomSelect($(this))
			});
			$('.product-form_composite').hide();
			$('.product-form_details').show();

			$([document.documentElement, document.body]).animate({
				scrollTop: $(".product-form_composite-badge").offset().top
			}, 500);
		}
			, 50);
	});

	$(document).on('change', '.add-backup', async function () {
		blockUI('#minicart')
		// handleBackupSuit(selector, cartPage);
		if ($(this).hasClass('cart')) {
			await handleBackupSuit($(this), true)
			$('[name="update_cart"]').prop('disabled', false)
			$('[name="update_cart"]').trigger('click')
		} else {
			await handleBackupSuit($(this), true)
			await update_minicart() // Refresh the cart to reflect the updated price
		}
		unblockUI('#minicart')
	})

	$(document).on('click', '[name="add-to-cart"]', function () {
		// if(!$(this).hasClass('js-entireOutfit-atc')){
		// 	$('.product-info').find('button[name=add-to-cart]').show();
		// }
		if ($('body').hasClass('woocommerce-cart')) {
			// customAddtoCart({atc_element}, {page_reference})
			customAddtoCart($(this), true)
		} else {
			customAddtoCart($(this), false)
		}
	})

	// Handle Backup Suit
	$(document).on(
		'change',
		'form.addtocart-form input[name="backup_suit"]',
		async function () {
			// handlePriceDisplay({selector_element}, {page_reference})
			await handlePriceDisplay($(this))
			await handleEntireOutfitPrice()
		}
	)

	$(document).on(
		'change',
		'form.addtocart-form select[name="rental_duration"]',
		async function () {
			await handlePriceDisplay($(this))
			await handleEntireOutfitPrice()
		}
	)

	// Product Page Specific Functions
	if ($('body').hasClass('product-template-default')) {
		handleEntireOutfitPrice()

		// Updating Delivery Date of upsells
		$('form.addtocart-form').on(
			'change',
			'input[name="delivery_date"]',
			function () {
				handleUpsellDelivery($(this))
			}
		)

		// Updating product attributes on upsells
		$('form.addtocart-form.ori-product').on(
			'change',
			'select[name^="size["]:not([name="size[pa_size-of-suit-trouser]"])',
			function () {
				handleUpsellAttributes($(this))
			}
		)

		// Updating price of upsells on the basis of selection in main product
		$('form.addtocart-form').on(
			'change',
			'select[name="rental_duration"]',
			async function () {
				// handlePriceDisplay({selector_element}, {page_reference})
				await handlePriceDisplay($(this))
				await handleUpsellRental($(this))
				await handleEntireOutfitPrice()
			}
		)

		// Load storage data only if its edit product page
		if (
			(location.search.split(name + '=')[1] || '').split('&')[0] != '' &&
			$('body').hasClass('single-product')
		) {
			var cartForm = $('form.addtocart-form.ori-product')
			var key = $(cartForm).attr('id')
			setTimeout(function () {
				loadStorageData(key)
				$(cartForm)
					.find('input, select')
					.each(async function () {
						if ($(this).attr('name').includes('delivery_date')) {
							datePicker($(this))
							handleUpsellDelivery($(this))
						} else if (
							$(this).attr('name').includes('rental_duration')
						) {
							await handleUpsellRental($(this), true)
						} else if (
							!$(this)
								.attr('name')
								.includes('pa_size-of-suit-trouser')
						) {
							handleUpsellAttributes($(this))
						}
					})
				handleEntireOutfitPrice()
			}, 1000)
		}

		// Product zoom functionality
		if ($(window).width() > 768) {
			setTimeout(function () {
				productZoom()
			}, 1000)
		}
	}

	// Functions to be excluded form Cart or Checkout Pages (Minicart specific functions)
	if (
		!$('body').hasClass('woocommerce-cart') &&
		!$('body').hasClass('woocommerce-checkout')
	) {
		// Update minicart according to current state
		update_minicart()

		// Remove item from cart
		$(document).on('click', '#minicart .remove', function (event) {
			event.preventDefault()
			console.log('removing item from cart...')
			customRemovefromCart($(this))
		})

		$(document).on('click', '.cart-open', function () {
			$('#shadow').show()
			$('#minicart').show()
			$('#minicart').addClass('active')
			$('#tidio-chat-iframe').css('z-index', '10')
		})

		$(document).on('click', '.cart-close', function () {
			$('#minicart').removeClass('active')
			$('#shadow').hide()
		})
	}

	// $('select').on('change', function (e) {
	// 	var optionSelected = $("option:selected", this);
	// 	var val = this.value;
	// 	if(val){
	// 		let originalcartForm = $(this).closest('.addtocart-form');
	// 		// let cartPage = $(this).closest('.addtocart-form');
	// 		validateForm(originalcartForm);

	// 	}
	// });
}

function productZoom() {
	const zoomableImages = document.querySelectorAll('.product-images .product-slider img');

	zoomableImages.forEach(image => {
		if (image.naturalWidth > 500) {
			$(image).addClass('zoomable-image');
			$(image).elevateZoom({
				zoomType: "inner",
				preloading: 0,
				zoomWindowFadeIn: 500,
				zoomWindowFadeOut: 750
			});
		}
	});
};

async function handlePriceDisplay(currentSelector, updatePage = false) {
	var cartForm = $(currentSelector).closest('form.addtocart-form')
	var finalprice = parseFloat(
		$(cartForm).find('.js-final-price').attr('data-p')
	)
	var oriprice = parseFloat(
		$(cartForm).find('.js-final-price').attr('data-orip')
	)
	if (updatePage) {
		if ($(currentSelector).attr('name') == 'backup_suit') {
			if ($(currentSelector).is(':checked')) {
				finalprice += parseFloat($(currentSelector).attr('data-p'))
			}
		} else if ($(currentSelector).attr('name') == 'rental_duration') {
			var surcharge = parseFloat(
				$(currentSelector).find('option[value="14"').attr('data-rate')
			)
			if ($(currentSelector).val() == 14) {
				if (!(oriprice + surcharge >= finalprice)) {
					finalprice += parseFloat(
						$(currentSelector)
							.find('option[value="14"')
							.attr('data-rate')
					)
				}
			}
		}
	} else {
		if ($(currentSelector).attr('name') == 'backup_suit') {
			if ($(currentSelector).is(':checked')) {
				finalprice += parseFloat($(currentSelector).attr('data-p'))
			} else {
				finalprice -= parseFloat($(currentSelector).attr('data-p'))
			}
		} else if ($(currentSelector).attr('name') == 'rental_duration') {
			var surcharge = parseFloat(
				$(currentSelector).find('option[value="14"').attr('data-rate')
			)
			if ($(currentSelector).val() == 14) {
				if (oriprice + surcharge > finalprice) {
					finalprice += surcharge
				}
			} else {
				if (finalprice - surcharge >= oriprice) {
					finalprice -= surcharge
				}
			}
		}
	}

	updateFinalPrice(finalprice, cartForm)
}

async function handleUpsellRental(currentSelector, updatePage = false) {
	var upsells = $('form.addtocart-form.upsell-product')

	if (upsells) {
		var upsellsArray = Array.from(upsells)
		for (let upsell of upsellsArray) {
			var rental = $(upsell).find('select[name="rental_duration"]')
			if (rental) {
				rental.val($(currentSelector).val())
				handlePriceDisplay(rental)
			}
		}
	}
}

function handleUpsellDelivery(currentSelector) {
	var upsell = $('form.addtocart-form.upsell-product')
	if (upsell) {
		var upsellDelivery = upsell.find('input[name="delivery_date"]')
		if (upsellDelivery) {
			upsellDelivery.val($(currentSelector).val())
		}
	}
}

function handleUpsellAttributes(currentSelector) {
	var selectedAttr = $(currentSelector).val();
	if (!selectedAttr) return;
	var selectedSize = selectedAttr.split('|')[0];
	var upsells = $('form.addtocart-form.upsell-product');
	if (upsells) {
		upsells.each(function () {
			var upsellAttr = $(this).find('select[name^=size');
			if (upsellAttr) {
				$(upsellAttr)
					.find('option')
					.each(function () {
						// $(this).prop('selected', false).removeAttr('selected');
						if ($(this).val().startsWith(selectedSize)) {
							setTimeout(() => {
								$(upsellAttr).val($(this).val()).trigger('change');
								// console.debug('$(this).val(): ' + $(this).val() + '. Setted $(upsellAttr).val(): ' + $(upsellAttr).val())
							}, 200);
							// $(this).prop('selected', true).attr('selected', 'selected');
							// return false; // Break the loop
							// break;
						}
					});
				// $(upsellAttr).trigger('change');
			}
		})
	}
}

async function handleEntireOutfitPrice() {
	var entirePrice = parseFloat(
		$('form.addtocart-form.ori-product .js-final-price').attr('data-p')
	)
	var upsells = $('.product-content form.addtocart-form.upsell-product')
	if (upsells) {
		var upsellsArray = Array.from(upsells)
		upsellsArray.forEach((elm) => {
			entirePrice += parseFloat(
				$(elm).find('.js-final-price').attr('data-p')
			)
		})
	}

	updateFinalPrice(entirePrice, $('.js-entireOutfit-atc'))
}

function updateFinalPrice(finalprice, cartForm) {
	$(cartForm).find('.js-final-price').text(finalprice)
	$(cartForm).find('.js-final-price').attr('data-p', finalprice)
}

function loadStorageData(key) {
	if (Cookies.get(key.toString()) !== undefined) {
		const savedData = JSON.parse(Cookies.get(key.toString())) // get and parse the saved data from localStorage
		$('#' + key)
			.find('input, select')
			.each(function () {
				var fieldName = $(this).attr('name')
				if (fieldName in savedData) {
					if (fieldName == 'backup_suit') {
						if (savedData[fieldName] == 'added') {
							$(this).prop('checked', true) // for checkbox
						}
					} else {
						if ($(this).val() != savedData[fieldName]) {
							setTimeout(() => {
								$(this).val(savedData[fieldName]).trigger('change');
							}, 200);
							// $(this).find('option').each(function () {$(this).prop('selected', false).removeAttr('selected');});
							$(this)
								.find(
									'option[value="' +
									savedData[fieldName] +
									'"]'
								)
								.prop('selected', true).attr('selected', 'selected');
						}
					}
				}
				if (
					fieldName.startsWith('size[') ||
					fieldName == 'rental_duration'
				) {
					styleCustomSelect($(this))
				}
			})
		updateFinalPrice(savedData['price'], $('#' + key))
	}
}

async function customAddtoCart($this, cartPage) {
	blockUI('#minicart')
	setProductDefaults();
	if ($this.hasClass('js-entireOutfit-atc')) {
		var validated = []
		var originalcartForm = $this
			.closest('.product-hero')
			.find('form.addtocart-form.ori-product')

		validated.push(validateForm(originalcartForm, cartPage))

		var upsellscartForms = $this
			.closest('.section-upsells')
			.find('form.addtocart-form.upsell-product')
		if (upsellscartForms) {
			var upsellscartFormArray = Array.from(upsellscartForms)
			for (let upsellcartForm of upsellscartFormArray) {
				validated.push(validateForm($(upsellcartForm), cartPage))
			}
		}
		if (!validated.includes(false)) {
			if (upsellscartFormArray) {
				var cartForms = [originalcartForm].concat(upsellscartFormArray)
				await addToCartCall(cartForms)
				$this.html('Entire Outfit Added to the cart!')
			} else {
				await addToCartCall([originalcartForm])
			}

			await update_minicart(true)
			// $('.product-info').find('button[name=add-to-cart]').show();
			$('form.upsell-product').find('.product-info').slideUp();
		} else {
			toastr.error('Please Select the required options!', 'Error !', {
				closeButton: true,
				progressBar: true,
				timeOut: 5000,
			})
		}
	} else {
		var validated = false
		var cartForm = $this.closest('form.addtocart-form')
		validated = validateForm($this.closest('form.addtocart-form'), cartPage)
		if (validated) {
			await addToCartCall([cartForm])

			if (cartPage) {
				window.location.href = baseurl + '/cart'
			} else if (cartForm.hasClass('miniCartForm')) {
				await update_minicart(false)
			} else {
				await update_minicart(true)
			}
			// $('.product-info').find('button[name=add-to-cart]').show();
		} else {
			toastr.error('Please Select the required options!', 'Error !', {
				closeButton: true,
				progressBar: true,
				timeOut: 5000,
			})
		}
	}
	unblockUI('#minicart')
}

function validateForm(cartForm, cartPage) {
	var encounteredError = []
	var upsellAtcForm = $(cartForm).hasClass('upsell-product')
	$(cartForm)
		.find('input, select')
		.each(function (index) {
			if ($(this).hasClass('addtocart-control')) {
				if (upsellAtcForm) {
					var oriProductAttr
					if (
						$(this).attr('name').includes('shirt') ||
						$(this).attr('name').includes('vest')
					) {
						oriProductAttr = $('.ori-product').find(
							'select[name^="size["]:not([name="size[pa_size-of-suit-trouser]"])'
						)
					} else if ($(this).attr('name').includes('shoes')) {
						oriProductAttr = ''
					} else {
						oriProductAttr = $('.ori-product').find(
							'[name=' + $(this).attr('name') + ']'
						)
					}
				}
				if ($(this).prop('required') && !$(this).val()) {
					encounteredError.push(true)
					$(this).addClass('error-field')
					if (!$(this).next('.field-error').length) {
						$(this).after(
							'<span class="field-error">Please Select the value</span>'
						)
					}
					if (upsellAtcForm && oriProductAttr != '' && !$(oriProductAttr).val()) {
						oriProductAttr.addClass('error-field')
						if (!oriProductAttr.next('.field-error').length) {
							oriProductAttr.after(
								'<span class="field-error">Please Select the value</span>'
							)
						}
					}
					if (cartPage) {
						$(cartForm).find('.product-info').slideDown()
					} else {
						if (
							upsellAtcForm &&
							$(cartForm).attr('data-cat').includes('shoe')
						) {
							$(cartForm).find('.product-info').slideDown()
							$('.ori-product')
								.parents('.product-info')
								.slideDown()
						} else if ($(cartForm).hasClass('ori-product')) {
							$(cartForm).parents('.product-info').slideDown()
						} else if ($(cartForm).hasClass('miniCartForm')) {
							$(cartForm).find('.product-info').slideDown()
						} else {
							$('.ori-product')
								.parents('.product-info')
								.slideDown()
						}
					}
				} else {
					encounteredError.push(false)
					$(this).removeClass('error-field')
					if ($(this).next('.field-error').length) {
						$(this).next('.field-error').remove()
					}
					if (upsellAtcForm && oriProductAttr != '') {
						oriProductAttr.removeClass('error-field')
						if (oriProductAttr.next('.field-error').length) {
							oriProductAttr.next('.field-error').remove()
						}
					}
				}
			}
		})
	if (!encounteredError.includes(true)) {
		return true
	}
	return false
}

function addToCartCall(cartForms) {
	var form_data_array = []
	setProductDefaults();
	cartForms.forEach(function (cartForm) {
		var formString = $(cartForm).find(':input').serialize()
		form_data_array.push(formString)
	})
	return $.ajax({
		url: ajaxurl,
		method: 'post',
		dataType: 'json',
		data: {
			action: 'custom_add_to_cart',
			form_data_array,
		},
		success: function (response) {
			console.log(response)
			if (response.status) {
				cartForms.forEach(function (form) {
					var key = $(form).attr('id')
					var data = {}
					data['cart_key'] = response.cart_key
					data['price'] = parseFloat(
						$(form).find('.js-final-price').attr('data-p')
					)

					$.each($(form).serializeArray(), function (index, field) {
						if (
							$.inArray(field.name, [
								'cart_item_key',
								'product_type',
								'product_id',
								'pkg_name',
							]) < 0
						) {
							if (
								field.name == 'backup_suit' &&
								$('input[name=' + field.name + ']').is(
									':checked'
								)
							) {
								data[field.name] = 'added'
							} else {
								data[field.name] = field.value
							}
						}
					})

					Cookies.set(key, JSON.stringify(data))

					// Update button text for each form
					$(form).find('[name="add-to-cart"]').html(response.message)
				})
			} else {
				toastr.error(response.message, 'Error !', {
					closeButton: true,
					progressBar: true,
					timeOut: 5000,
				})
			}
		},
		error: function (data) {
			console.log('inside ajax error')
		},
	})
}

function updateCartCount() {
	$.ajax({
		url: ajaxurl,
		method: 'POST',
		data: {
			action: 'get_cart_item_count',
		},
		success: function (response) {
			console.log('cart count: ' + response)
			$('#cart-count').text(response)
			if (parseInt(response) > 0) {
				$('#cart-count').show()
			}
		},
		error: function (data) {
			console.log('inside ajax error')
		},
	});
}

function update_minicart(showCart = false) {
	return $.ajax({
		url: ajaxurl,
		method: 'POST',
		data: {
			action: 'load_cart_contents',
			_: new Date().getTime(), // Append timestamp to prevent caching
		},
		success: function (response) {
			$('#minicart #minicart-content').html(response)
			$('#minicart-content').find('.cart-clear').on('click', function () {
				clearCart()
			})

			$('#minicart-content').find('input').on('change', function () {
				if (parseInt($(this).val()) < 0) {
					$(this).val(0);
					quantities($(this));
				}
			});

			// debugger;
			// jQuery('[data-toggle="tooltip"]').tooltip();

			updateCartCount()
			updateMiniCartDefaults();
			// restoreProductDefaults();
			if (showCart) {
				$('#shadow').show()
				$('#minicart').show()
				$('#minicart').addClass('active')
				$('#tidio-chat-iframe').css('z-index', '10')
			}

			// date picker
			datePicker($('.minicart .delivery_date'))
			$('.minicart select.custom-select').each(function () {
				styleCustomSelect($(this))
			})
		},
		error: function (data) {
			console.log('Inside Ajax Error!')
		},
	})
}

function quantities($this) {
	blockUI('#minicart')
	var elm = $this.parents('.quantity-box').find('input.qty_field')
	if ($this.hasClass('add')) {
		$(elm).val(parseInt($(elm).val()) + 1)
	}

	if ($this.hasClass('sub')) {
		let newVal = parseInt($(elm).val()) - 1;
		$(elm).val(newVal < 0 ? 0 : newVal)
	}

	if ($this.hasClass('cart')) {
		$('[name="update_cart"]').prop('disabled', false)
		$('[name="update_cart"]').trigger('click')
		unblockUI('#minicart')
	} else if ($this.hasClass('mincart') || $this.hasClass('qty_field')) {
		const itemKey = $this.parents('.cart_item').data('cart-key')
		const quantity = $(elm).val()

		$.ajax({
			url: ajaxurl,
			method: 'POST',
			data: {
				action: 'update_cart_item_quantity',
				cart_item_key: itemKey,
				quantity: quantity,
			},
			success: async function (response) {
				await update_minicart() // Refresh the cart to reflect the updated quantity
				unblockUI('#minicart')
			},
			error: function (data) {
				console.log('inside ajax error')
			},
		})
	}
}

function customRemovefromCart($this) {
	blockUI('#minicart');
	const itemKey = $this.parents('.cart_item').data('cart-key')
	$.ajax({
		url: ajaxurl,
		method: 'POST',
		data: {
			action: 'update_cart_item_quantity',
			cart_item_key: itemKey,
			quantity: 0,
		},
		success: async function (response) {
			await update_minicart() // Refresh the cart to reflect the updated quantity
			unblockUI('#minicart');
		},
		error: function (data) {
			console.log('inside ajax error')
			unblockUI('#minicart');
		},
	})
}

function handleBackupSuit($this, cartPage) {
	var form_data = $('form.woocommerce-cart-form').serialize();
	return $.ajax({
		url: ajaxurl,
		method: 'post',
		dataType: 'json',
		data: {
			action: 'custom_update_cart',
			form_data,
		},
		success: function (response) {
			if (response.status) {
				var subtotalElement = $this
					.parents('.cart_item')
					.find('.js-subtotal')
				var quantity = parseInt(
					$this.parents('.cart_item').find('input.qty_field').val()
				)
				var subtotal = parseFloat($(subtotalElement).attr('data-p'))
				if (cartPage) {
					if ($this.is(':checked')) {
						subtotal += parseFloat($this.attr('data-p')) * quantity
					} else {
						subtotal -= parseFloat($this.attr('data-p')) * quantity
					}
					$(subtotalElement).html(subtotal)
					$(subtotalElement).attr('data-p', subtotal)
				}
				if (response.backup_suit_data) {
					var key = response.backup_suit_data.local_storage_key
					if (Cookies.get(key.toString()) !== undefined) {
						var keyData = JSON.parse(Cookies.get(key.toString()))
						if (response.backup_suit_data.backup_suit) {
							keyData['backup_suit'] = 'added'
						} else {
							delete keyData['backup_suit']
						}
						keyData['price'] = subtotal
						Cookies.set(key, JSON.stringify(keyData))
					}
				}
				$(document.body).trigger('wc_fragment_refresh')
			}
		},
		error: function (data) {
			console.log('inside ajax error')
		},
	});
}

function datePicker($this) {
	$this.flatpickr({
		dateFormat: 'd-M-Y',
		minDate: (function () {
			let minDate = new Date();
			let hours = parseInt(new Date().toLocaleString("en-US", {
				timeZone: "NZ",
				hour12: false,
				hour: '2-digit'
			}));
			// let hours = minDate.getHours();
			minDate.setDate(minDate.getDate() + 1)
			if (hours >= 16) {
				minDate.setHours(0, 0, 0, 0);
				minDate.setDate(minDate.getDate() + 1)
			}
			return minDate;
		})(),
		onOpen: function (selectedDates, dateStr, instance) {
			$('.flatpickr-calendar').append(
				'<p>Pick a Delivery Date 2-5 days before your event</p>'
			)
			$('.flatpickr-calendar').append(
				"<div class='calender-legend'><div class='start'><span class='box'></span><span class='text'>At Your door<br />by 8pm</span></div><div class='end'><span class='box'></span><span class='text'>Drop in mail<br />by 5pm</span></div></div>"
			)
		},
		// onMonthChange: function(selectedDates, dateStr, instance) {
		//     dateRange(instance);
		// },
		// selectedDates: Date[], dateStr: string, instance: any
		onChange: function (selectedDates, dateStr, instance) {
			dateRange(instance)
			let elem = $(instance.element);
			if (elem.next('.field-error').length && elem.val()) {
				elem.next('.field-error').remove();
				$(instance.element).removeClass('error-field');
			}
			setTimeout(instance.close(), 300);
		},
		disable: [
			function (date) {
				// return true to disable
				return date.getDay() === 0 || date.getDay() === 6
			},
		],
	})
}

function dateRange(instance) {
	var pkg_id = $(instance.input).attr('data-pkg_id')
	var rental_duration = $(instance.input)
		.closest('form.addtocart-form')
		.find('#rental_' + pkg_id)
		.val()

	if (rental_duration) {
		rental_duration = parseInt(rental_duration)
		var daySpan = $(instance.daysContainer).find('.flatpickr-day.selected')
		daySpan.addClass('startRange')

		for (var i = 1; i < rental_duration; i++) {
			daySpan.removeClass('flatpickr-disabled')
			daySpan = daySpan.next()
			daySpan.addClass('selected')

			if (i == rental_duration - 1) {
				daySpan.addClass('endRange')
			}
		}
		instance.open()
	}
}

/* Reference: http://jsfiddle.net/BB3JK/47/ */
function styleCustomSelect($select) {
	var numberOfOptions = $select.children('option').length
	var $styledSelect
	var $list
	let isNew
	if (!$select.hasClass('select-hidden')) {
		isNew = true
		$select.addClass('select-hidden')
		$select.wrap('<div class="custom-select"></div>')
		$select.after('<div class="select-styled"></div>')

		$styledSelect = $select.next('div.select-styled')
		$styledSelect.text($select.children('option').eq(0).text())

		$list = $('<ul />', {
			class: 'select-options',
		}).insertAfter($styledSelect)

		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $select.children('option').eq(i).text(),
				rel: $select.children('option').eq(i).val(),
			}).appendTo($list)
		}
	} else {
		$styledSelect = $select.next('div.select-styled')
		$styledSelect.text($select.children('option').eq(0).text())
		$list = $select.next('ul.select-options')
	}

	for (var i = 0; i < numberOfOptions; i++) {
		if ($select.children('option').eq(i).is(':selected')) {
			$(
				'li[rel="' + $select.children('option').eq(i).val() + '"]'
			).addClass('is-selected')
			$styledSelect.text($select.children('option').eq(i).text())
			break
		}
	}

	if (!isNew) return

	$styledSelect.click(function (e) {
		e.stopPropagation()
		$('div.select-styled.active')
			.removeClass('active')
			.next('ul.select-options')
			.hide()
		setTimeout(() => $(this).toggleClass('active').next('ul.select-options').toggle(), 50);
	})

	var $listItems = $list.children('li')
	$listItems.click(function (e) {
		e.stopPropagation()
		$styledSelect.text($(this).text()).removeClass('active')
		$list.find('li.is-selected').removeClass('is-selected')
		$list
			.find('li[rel="' + $(this).attr('rel') + '"]')
			.addClass('is-selected')
		$list.hide()
		$select.val($(this).attr('rel'))
		if ($select.next().hasClass('field-error') && $($select).val() != '') {
			$select.next().remove();
		}
		$select.trigger('change');
		// setTimeout(()=>$($select).trigger('change'), 200);
	})

	$(document).click(function () {
		$styledSelect.removeClass('active');
		$list.hide();
	})
}

function updateMiniCartDefaults() {
	handleUpsellRental($('#addtocart select[name="rental_duration"]'));
	handleUpsellDelivery($('#addtocart input[name="delivery_date"]'));
	handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-suit-jacket-shirt]"]'));
	handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-suit-trouser]"]'));
	handleUpsellAttributes($('#addtocart select[name="size[pa_jacket-size]"]'));
	handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-vest]"]'));
	handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-shirt]"]'));
}

function setProductDefaults() {
	// let jacketSize = $('#addtocart select[name="size[pa_size-of-suit-jacket-shirt]"], #addtocart select[name="size[pa_jacket-size]"], #addtocart select[name="size[pa_size-of-shirt]"], #addtocart select[name="size[pa_size-of-vest]"]').filter(itm => itm.val != '');
	let jacketSize = $('#addtocart select[name="size[pa_size-of-suit-jacket-shirt]"]')
		|| $('#addtocart select[name="size[pa_jacket-size]"]')
		|| $('#addtocart select[name="size[pa_size-of-shirt]"]')
		|| $('#addtocart select[name="size[pa_size-of-vest]"]');
	if (jacketSize.length) {
		sessionStorage.setItem('jacketSize', $(jacketSize[0]).val());
	}

	let trousersSize = $('#addtocart select[name="size[pa_size-of-suit-trouser]"]').val();
	trousersSize && sessionStorage.setItem('trousersSize', trousersSize);

	let shoesSize = $('#addtocart select[name="size[pa_size-of-suit-shoes]"]').val();
	shoesSize && sessionStorage.setItem('shoesSize', shoesSize);

	let rentalDuration = $('#addtocart select[name="rental_duration"]').val();
	rentalDuration && sessionStorage.setItem('rentalDuration', parseInt(rentalDuration));

	let deliveryDate = $('#addtocart input[name="delivery_date"]').val();
	deliveryDate && sessionStorage.setItem('deliveryDate', deliveryDate);

}

function restoreProductDefaults() {
	if (!$('section#product-hero').length) return;

	let rentalDuration = sessionStorage.getItem('rentalDuration');
	if (rentalDuration != null) {
		$('select[name="rental_duration"]').val(rentalDuration);
		handleUpsellRental('#addtocart select[name="rental_duration"]');
		handlePriceDisplay('#addtocart select[name="rental_duration"]');
	}

	let deliveryDate = sessionStorage.getItem('deliveryDate');
	if (deliveryDate != null) {
		$('input[name="delivery_date"]').val(deliveryDate);
		handleUpsellDelivery('#addtocart input[name="delivery_date"]');
	}

	let jacketSize = sessionStorage.getItem('jacketSize');
	if (jacketSize != null) {
		// $('select[name="size[pa_size-of-suit-jacket-shirt]"]').val(jacketSize);
		setSizeForProduct('select[name="size[pa_size-of-suit-jacket-shirt]"]', jacketSize);
		handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-suit-jacket-shirt]"]'));

		// $('select[name="size[pa_size-of-shirt]"]').val(jacketSize);
		setSizeForProduct('select[name="size[pa_size-of-shirt]"]', jacketSize);
		handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-shirt]"]'));

		// $('select[name="size[pa_jacket-size]"]').val(jacketSize);
		setSizeForProduct('select[name="size[pa_jacket-size]"]', jacketSize);
		handleUpsellAttributes($('select[name="size[pa_jacket-size]"]'));

		// $('select[name="size[pa_size-of-vest]"]').val(jacketSize);
		setSizeForProduct('select[name="size[pa_size-of-vest]"]', jacketSize);
		handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-vest]"]'));
	}

	let trousersSize = sessionStorage.getItem('trousersSize');
	if (trousersSize != null) {
		$('select[name="size[pa_size-of-suit-trouser]"]').val(trousersSize);
		setSizeForProduct('select[name="size[pa_size-of-suit-trouser]"]', trousersSize);
		handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-suit-trouser]"]'));
	}

	let shoesSize = sessionStorage.getItem('shoesSize');
	if (shoesSize != null) {
		// #addtocart - set size if it is main product
		$('#addtocart select[name="size[pa_size-of-suit-shoes]"]').val(shoesSize);
		setSizeForProduct('#addtocart select[name="size[pa_size-of-suit-shoes]"]', shoesSize);
		handleUpsellAttributes($('#addtocart select[name="size[pa_size-of-suit-shoes]"]'));
	}
}

// Remove all items from cart
function clearCart() {
	console.info('clear cart');
	blockUI('#minicart')
	$.ajax({
		url: ajaxurl,
		method: 'POST',
		data: {
			action: 'clear_cart_contents',
			_: new Date().getTime(), // Append timestamp to prevent caching
		},
		success: async function (response) {
			await update_minicart(true);
			unblockUI('#minicart')
		},
		error: function (data) {
			console.log('Inside Ajax Error!')
			unblockUI('#minicart')
		},
	});
}

function setSizeForProduct($select, attrSize) {
	let selectedSize = attrSize.split('|')[0];
	// var attrs = $(this).find('select[name^=size');
	if ($select) {
		$($select)
			.find('option')
			.each(function () {
				//  $(this).prop('selected', false).removeAttr('selected');
				$(this).prop('selected', false).removeAttr('selected');
				if ($(this).val().startsWith(selectedSize)) {
					setTimeout(() => {
						$($select).val($(this).val()).trigger('change');
						// console.debug('$(this).val(): ' + $(this).val() + '. Setted $($select).val(): ' + $($select).val());
					}, 200);

					$(this).prop('selected', true).attr('selected', 'selected');
					// $($select).val(attrSize);
					// return false; // Break the loop
				}
			});
		// $($select).trigger('change');
	}
}

// For minicart
function blockUI(el) {
	$('#loader').show();
	let xel = $(el);
	if (!xel || !xel.length) { return; }
	// $(el[0]).css('overflow', 'none');
	$(xel[0]).css('z-index', 100);
}


// For minicart
function unblockUI(el) {
	$('#loader').hide();
	let xel = $(el);
	if (!xel || !xel.length) { return; }
	// $(el[0]).css('overflow', 'auto');
	$(xel[0]).css('z-index', 9999999); // For minicart
}

// function preloadPackageData() {
// 	$('.product-form_composite').each(function (idx) {
// 		let self = this;
// 		let btn = $(this).find('.slide1_action-nextstep');
// 		let container = $(self).find('.slide2');

// 		$.ajax({
// 			url: ajaxurl,
// 			// dataType: 'json',
// 			method: 'POST',
// 			data: {
// 				action: 'load_product_form_contents',
// 				product_id: $(btn).data('product_id'),
// 				package_id: $(btn).data('package_id'),
// 				hide_block_id: true
// 			},
// 			success: function (response) {
// 				container.append(response);
// 				container.data('package_name', $(btn).data('package_name'));
// 				container.data('package_desc', $(btn).data('package_desc'));
// 			},
// 			error: function (data) {
// 				console.log('inside ajax error');
// 			},
// 		});
// 	});
// }